import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  userGetScoreData,
  userChangeEdit,
  changeName,
  changeLastName,
  changeEmail,
  changeDniType,
  changeGender,
  changeCellPhone,
  changeDni,
} from '../../../store/actions/UserActions';
import { changeVisible } from '../../../store/actions/PaymentActions';
import PageLoader from '../../PageLoader';
import RechargeHeader from '../../RechargeHeader';
const { REACT_APP_PRICE_SUBSCRIPTION } = process.env;

class ProfileData extends Component {
  componentDidMount() {
    const {
      userReducer: { user_score_loading, user },
      userGetScoreData,
      changeName,
      changeLastName,
      changeEmail,
      changeDniType,
      changeGender,
      changeCellPhone,
      changeDni,
    } = this.props;

    if (user_score_loading) {
      userGetScoreData();
    }

    changeName(user.nombre);
    changeLastName(user.apellido);
    changeEmail(user.email);
    changeDniType(user.tipo_documento);
    changeGender(user.sexo);
    changeCellPhone(user.telefono);
    changeDni(user.documento);
  }

  render() {
    const {
      userReducer: {
        user_score_loading,
        user_score,
        user_email,
        user_name,
        user_lastName,
        user_dniType,
        user_dni,
        user_cellPhone,
        user_doc_types,
      },
      globalReducer: { global_cine_mas },
      userChangeEdit,
      changeVisible,
    } = this.props;

    const docType = user_dniType ? user_dniType : '';
    let docTypeName = user_doc_types.find((item) => item.id === docType);
    docTypeName = docTypeName ? docTypeName.name : '';

    const numberFormat = new Intl.NumberFormat('es-CO');
    const subscriptionPrice = global_cine_mas
      ? global_cine_mas.price_subscription
      : REACT_APP_PRICE_SUBSCRIPTION;

    return (
      <div className="row">
        <div className="col-md-12">
          <h4>MI PERFIL</h4>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            {!user_score_loading && user_score && (
              <div className="Profile_subscription-header">
                {user_score.Estado === 'Usuario sin tarjeta activa' ? (
                  <button
                    type="button"
                    className="Profile_subscription-button"
                    onClick={() => changeVisible('visible', 3)}
                  >
                    <span className="icon-hand" />
                    <div>
                      Adquirir <strong>CINE+</strong> por solo
                      <div className="Profile_subscription-price">
                        ${numberFormat.format(subscriptionPrice)}
                      </div>
                    </div>
                  </button>
                ) : (
                  <RechargeHeader />
                )}
              </div>
            )}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>NOMBRE:</label>
                  <div type="text" className="form-control">
                    {user_name}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>APELLIDOS:</label>
                  <div type="text" className="form-control">
                    {user_lastName}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>EMAIL:</label>
                  <div type="text" className="form-control">
                    {user_email}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>TELÉFONO:</label>
                  <div type="text" className="form-control">
                    {user_cellPhone}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>TIPO DE DOCUMENTO:</label>
                  <div type="text" className="form-control">
                    {docTypeName}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>NÚMERO DE DOCUMENTO:</label>
                  <div type="text" className="form-control">
                    {user_dni}
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-3">
                <button
                  className="btn btn-block btn-red"
                  onClick={() => userChangeEdit(true)}
                >
                  ACTUALIZAR MI PERFIL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, globalReducer }) => {
  return {
    userReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  userGetScoreData,
  userChangeEdit,
  changeName,
  changeLastName,
  changeEmail,
  changeDniType,
  changeGender,
  changeCellPhone,
  changeDni,
  changeVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileData);
