import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { contentGetDetail } from '../../store/actions/ContentActions';
import './ImaxHeader.css';

class ImaxHeader extends Component {
  componentDidMount() {
    const {
      contentReducer: { content_detail },
      contentGetDetail,
    } = this.props;

    if (!content_detail) {
      contentGetDetail('imax-video');
    }
  }

  render() {
    const {
      contentReducer: { content_detail },
    } = this.props;

    return (
      <div className="ImaxHeader-container">
        {content_detail && (
          <div className="ImaxHeader-content vertical-gradient">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: content_detail.content }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="ImaxHeader-menu">
          <NavLink to="/ciudad/bogota/teatros/imax" activeClassName="active">
            <div className="ImaxHeader-link">
              Cartelera
              <br />
              IMAX
            </div>
            <div className="ImaxHeader-active" />
          </NavLink>
          <NavLink to="/proximos-estrenos" activeClassName="active">
            <div className="ImaxHeader-link">
              Próximos
              <br />
              Estrenos IMAX
            </div>
            <div className="ImaxHeader-active" />
          </NavLink>
          <NavLink to="/teatros/imax/que-es" activeClassName="active">
            <div className="ImaxHeader-link">¿Qué es IMAX?</div>
            <div className="ImaxHeader-active" />
          </NavLink>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ contentReducer }) => {
  return {
    contentReducer,
  };
};

const mapDispatchToProps = {
  contentGetDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImaxHeader);
