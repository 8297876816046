import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { changeModalLogin } from '../../../store/actions/UserActions';
import { theaterDate } from '../../../store/actions/TheaterActions';
import PageLoader from '../../PageLoader';
import SectionTitle from '../../SectionTitle';
import './ImaxMovies.css';

function ImaxMovies(props) {
  const {
    theaterReducer: {
      theater_loading,
      theater_movies,
      theater_date,
      theater_date_menu,
    },
    userReducer: { user },
    match: {
      params: { city },
    },
    changeModalLogin,
    theaterDate,
    can_sell,
  } = props;

  if (theater_loading) {
    return <PageLoader />;
  }

  const renderShowtimes = (showtimes, movie_slug) => {
    const {
      globalReducer: { global_setting_payments },
    } = props;

    let html = showtimes.map((item, index) => {
      let { list_showtimes, name } = item;
      let list = list_showtimes.filter(
        (showtime) => showtime.fecha_funcion === theater_date
      );
      list = list.sort(
        (a, b) =>
          parseInt(a.hora_funcion.replaceAll(':', '')) -
          parseInt(b.hora_funcion.replaceAll(':', ''))
      );

      if (list.length) {
        return (
          <div key={index} className="ImaxMovies_row-list">
            <div className="ImaxMovies_name">{name}</div>
            <div className="ImaxMovies_showtimes">
              {!list.length ? (
                <h4>No hay funciones para la fecha seleccionada.</h4>
              ) : (
                <div className="ImaxMovies_showtimes-list">
                  {list.map((showtime) => {
                    if (!global_setting_payments || !can_sell) {
                      return (
                        <div className="button" key={showtime.id}>
                          {showtime.hora_cadena}
                        </div>
                      );
                    }
                    if (!user) {
                      return (
                        <button
                          type="button"
                          onClick={() => changeModalLogin('visible')}
                          key={showtime.id}
                        >
                          {showtime.hora_cadena}
                        </button>
                      );
                    }
                    return (
                      <Link
                        to={`/checkout/ciudad/${city}/pelicula/${movie_slug}/id/${showtime.movie_id}/showtime/${showtime.id}`}
                        key={showtime.id}
                      >
                        {showtime.hora_cadena}
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
      }
      return null;
    });

    html = html.filter((item) => item);

    return html;
  };

  return (
    <div className="ImaxMovies_container">
      <div className="ImaxMovies_title">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle
                title="Cartelera IMAX"
                subtitle="Bogotá"
                background="black"
                size="big"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="vertical-gradient">
        <div className="container">
          <div className="row">
            {theater_movies.length ? (
              <>
                <div className="col-md-12">
                  <h3>Selecciona el día de tu función</h3>
                </div>
                <div className="ImaxMovies_date-menu">
                  {theater_date_menu.map((date, index) => {
                    let selectec =
                      date.value === theater_date ? 'selected' : '';
                    return (
                      <div
                        key={index}
                        className={`ImaxMovies_date ${selectec}`}
                      >
                        <button onClick={() => theaterDate(date.value)}>
                          {date.name}
                        </button>
                      </div>
                    );
                  })}
                </div>
                {theater_movies.map((movie, index) => {
                  let showtimes = renderShowtimes(movie.list, movie.slug);
                  if (!showtimes[0]) {
                    return null;
                  }
                  return (
                    <div key={index} className="col-sm-6 col-md-4">
                      <div className="ImaxMovies_item">
                        <Link to={`/ciudad/${city}/pelicula/${movie.slug}`}>
                          <img src={movie.imagen} alt={movie.titulo} />
                        </Link>
                        <div className="ImaxMovies_item-title">
                          <h3>{movie.titulo}</h3>
                          <p>{movie.titulo_original}</p>
                        </div>
                        <div className="ImaxMovies_item-description">
                          {movie.sinopsis}
                        </div>
                        {showtimes}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="col-md-12">
                <h3>
                  Te esperamos en el teatro para comprar tus boletas en taquilla
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ theaterReducer, userReducer, globalReducer }) => {
  return {
    theaterReducer,
    userReducer,
    globalReducer,
  };
};

const mapDispatchToProps = {
  changeModalLogin,
  theaterDate,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImaxMovies)
);
