import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  theaterDetail,
  theaterMovies,
} from '../../store/actions/TheaterActions';
import Benefits from '../Benefits';
import HowToBuy from '../HowToBuy';
import ImaxHeader from '../ImaxHeader';
import MovieListTitle from '../MovieListTitle';
import PageLoader from '../PageLoader';
import TheaterSchedule from '../Theater/TheaterSchedule';
import ImaxMovies from './ImaxMovies';

class TheaterImax extends Component {
  componentDidMount() {
    const {
      match: {
        params: { theater },
      },
      theaterDetail,
      theaterMovies,
    } = this.props;

    theaterDetail(theater);
    theaterMovies(theater);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { theater: oldTheater },
      },
    } = prevProps;
    const {
      match: {
        params: { theater },
      },
      theaterDetail,
      theaterMovies,
    } = this.props;

    if (oldTheater !== theater) {
      theaterDetail(theater);
      theaterMovies(theater);
    }
  }

  render() {
    const {
      theaterReducer: { theater_detail },
    } = this.props;

    if (!theater_detail) {
      return <PageLoader />;
    }

    return (
      <div>
        <ImaxHeader />
        <MovieListTitle size="small" />
        <ImaxMovies can_sell={theater_detail.can_sell} />
        <TheaterSchedule />
        <HowToBuy />
        <Benefits />
      </div>
    );
  }
}

const mapStateToProps = ({ theaterReducer }) => {
  return {
    theaterReducer,
  };
};

const mapDispatchToProps = {
  theaterDetail,
  theaterMovies,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TheaterImax)
);
