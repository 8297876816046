import React from 'react';
import { connect } from 'react-redux';
import { getConfectioneryDetail } from '../../../Utils/confectioneryManager';
import ProductRecipe from './ProductRecipe';

function Recipe(props) {
  const {
    recipe,
    onSelectRecipeProducts,
    recipeProducts,
    confectioneryReducer: { confectionery_api_products },
  } = props;
  const principalProduct = getConfectioneryDetail(
    recipe.Codigo,
    confectionery_api_products
  );

  if (!principalProduct) {
    return null;
  }

  const onSelectProduct = (product, index = null) => {
    let firstProduct = recipe.Receta[0];
    if (recipe.Tipo === 'P') {
      onSelectRecipeProducts(product);
    } else {
      const isAdded = recipeProducts.find(
        (item) => item.Codigo === recipe.Codigo
      );
      const products = isAdded ? isAdded.Receta : [];
      let removeSelection = products.length ? products[index || 0] : false;

      removeSelection = removeSelection
        ? removeSelection.Codigo === product.Codigo
        : false;

      const data = {
        Codigo: recipe.Codigo,
        Descripcion: recipe.Descripcion,
        Tipo: recipe.Tipo,
      };
      products[index || 0] = removeSelection ? firstProduct : product;
      data.Receta = products;

      if (recipe.Cantidad) {
        data.Cantidad = recipe.Cantidad;
      }

      onSelectRecipeProducts(data);
    }
  };

  const renderProducts = (products, index) => {
    const html = [];

    products.forEach((product, productIndex) => {
      const recipeSelected = recipeProducts.find(
        (item) => item.Codigo === recipe.Codigo
      );
      let selected = '';
      if (recipeSelected && recipeSelected.Receta[index]) {
        if (recipeSelected.Tipo === 'P') {
          selected = 'selected';
        } else {
          if (recipeSelected.Receta[index]) selected = 'opacity';
          if (recipeSelected.Receta[index].Codigo === product.Codigo)
            selected = 'selected';
        }
      }

      html.push(
        <ProductRecipe
          product={product}
          key={product.Codigo}
          onSelectProduct={onSelectProduct}
          index={index}
          productIndex={productIndex}
          selected={selected}
        />
      );
    });

    return html;
  };

  const renderRows = () => {
    const html = [];

    for (let i = 0; i < recipe.Cantidad; i++) {
      if (recipe.Tipo === 'A') {
        const productApi = getConfectioneryDetail(
          recipe.Codigo,
          confectionery_api_products
        );
        if (productApi) {
          html.push(
            <div className="modalProduct_recipe-row" key={i}>
              {recipe.Cantidad > 1 && (
                <div className="modalProduct_recipe-row-title">
                  {productApi.category.toUpperCase()} {i + 1}
                </div>
                // <div className="modalProduct_recipe-row-title">
                //   {productApi.category.toUpperCase()} {i + 1}: (
                //   {productApi.name})
                // </div>
              )}
              {<div>{renderProducts(recipe.Receta, i)}</div>}
            </div>
          );
        }
      }
      if (recipe.Tipo === 'P') {
        const recipeSelected = recipeProducts.find(
          (item) => item.Codigo === recipe.Codigo
        );
        html.push(
          <div className="modalProduct_recipe-row" key={i}>
            <ProductRecipe
              product={recipe}
              onSelectProduct={onSelectProduct}
              selected={recipeSelected ? 'selected' : ''}
              productIndex={0}
            />
          </div>
        );
      }
    }

    return html;
  };

  return (
    <div className="modalProduct_recipe">
      {principalProduct.category_id === 1 ? (
        <div className="modalProduct_recipe-header">
          {principalProduct.name}
          {principalProduct.description && (
            <div className="modalProduct_recipe-description">
              {principalProduct.description}
            </div>
          )}
        </div>
      ) : (
        <div className="modalProduct_recipe-header">
          {principalProduct.category.toUpperCase()}
          {recipe.Cantidad > 1 ? 'S' : <span>({principalProduct.name})</span>}
          {principalProduct.description && (
            <div className="modalProduct_recipe-description">
              {principalProduct.description}
            </div>
          )}
        </div>
      )}
      <div className="modalProduct_recipe-body">{renderRows()}</div>
    </div>
  );
}

const mapStateToProps = ({ confectioneryReducer }) => {
  return {
    confectioneryReducer,
  };
};

export default connect(mapStateToProps, null)(Recipe);
