import React from 'react';
import DefaultLayout from '../../Layouts/DefaultLayout';
import './Cinemas.css';
import logo from '../../assets/images/cinemas.png';
import image01 from '../../assets/images/cinemas-01.png';
import image02 from '../../assets/images/cinemas-02.png';
import image03 from '../../assets/images/cinemas-03.png';
import image04 from '../../assets/images/cinemas-04.png';
import { connect } from 'react-redux';

const { REACT_APP_PRICE_SUBSCRIPTION } = process.env;

function CinemasPage(props) {
  const {
    globalReducer: { global_cine_mas },
  } = props;

  const numberFormat = new Intl.NumberFormat('es-CO');
  const subscriptionPrice = global_cine_mas
    ? global_cine_mas.price_subscription
    : REACT_APP_PRICE_SUBSCRIPTION;

  return (
    <DefaultLayout>
      <div className="Cinemas-container">
        <h1>CINE+</h1>
        <img className="Cinema-logo" src={logo} alt="Logo-Cinemas" />
        <img className="Cinema-01" src={image01} alt="Cinema-01" />
        <img className="Cinema-02" src={image02} alt="Cinema-02" />
        <img className="Cinema-03" src={image03} alt="Cinema-03" />
        <img className="Cinema-04" src={image04} alt="Cinema-04" />
      </div>
      <div className="Cinemas-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img className="Cinema-04-footer" src={image04} alt="Cinema-04" />
            </div>
            <div className="col-md-6">
              <ol>
                <li>Regístrate en www.procinal.com.co</li>
                <li>
                  Acércate a la taquilla y completa tu afiliación cancelando $ $
                  {numberFormat.format(subscriptionPrice)}
                </li>
                <li>
                  La recarga mínima es de $30.000 y se realizarán cargas en
                  múltiplos de $10.000
                </li>
                <li>Empieza a disfrutar del programa que te da</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

const mapStateToProps = ({ globalReducer }) => {
  return {
    globalReducer,
  };
};

export default connect(mapStateToProps, null)(CinemasPage);
